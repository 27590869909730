import "./App.css";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CacheBuster from 'react-cache-buster';
import appversion from '../package.json';
// import Banner from "./components/Banner";
import Spinner from "react-bootstrap/Spinner";
import MediaQuery from "react-responsive";

// import PerfectPlace from "./components/PerfectPlace";
// import PerfectPlaceMobile from "./components/PerfectPlaceMobile";

const Banner = lazy(() => import('./components/Banner'));
const PerfectPlace = lazy(() => import('./components/PerfectPlace'));
const PerfectPlaceMobile = lazy(() => import('./components/PerfectPlaceMobile'));
const Benefits = lazy(() => import('./components/Benefits'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const Disclosures = lazy(() => import('./components/Disclosures'));
const CyberSecurity = lazy(() => import('./components/CyberSecurity'));
const AccessibilityStatement = lazy(() => import('./components/AccessibilityStatement'));
const NMLSInformation = lazy(() => import('./components/NMLSInformation'));
const Terms = lazy(() => import('./components/TermsConditions'));
const Sitemap = lazy(() => import('./components/Sitemap'));
const Footer = lazy(() => import('./components/Footer'));
const Customer = lazy(() => import('./components/Customer/index'));
const CareerPage = lazy(() => import('./components/CareerPage/index'));
const ContactUs = lazy(() => import('./components/ContactUsPage'));
const Texasdisclosure = lazy(() => import('./components/Texasdisclosure/index'));
const Notfoundpage = lazy(() => import('./components/Notfoundpage/index'));
const Disclaimer = lazy(() => import('./components/Disclaimer/Disclaimer'));



function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [disclaimerData, setDisclaimerData] = useState("")

  useEffect(async()=>{
    try{
      const data = await fetch(process.env.REACT_APP_CDN_JSON);
      const jsonData = await data.json();
      setDisclaimerData(jsonData);
    }
    catch (error){
     console.log("Data Not Found", error)
    }

  },[])

  return (
    <CacheBuster
      currentVersion={appversion.version}
      isEnabled={true}
      isVerboseMode={false}
      metaFileDirectory={process.env.PUBLIC_URL}
    >
      <div className="App">
        <Suspense fallback={<div className="text-center"><Spinner /></div>}>
          <Banner />
          <MediaQuery minWidth={992}>
            <PerfectPlace />
          </MediaQuery>
          <MediaQuery maxWidth={991}>
            <PerfectPlaceMobile />
          </MediaQuery>
         {disclaimerData.disclaimer && <Disclaimer data={disclaimerData} />} 
          <Footer />
        </Suspense>
      </div>
    </CacheBuster>
  );
}

function App() {
  const [disclaimerData, setDisclaimerData] = useState("")

  useEffect(async()=>{
    try{
      const data = await fetch(process.env.REACT_APP_CDN_JSON);
      const jsonData = await data.json();
      setDisclaimerData(jsonData);
    }
    catch (error){
     console.log("Data Not Found", error)
    }

  },[])

  return (
    <CacheBuster
      currentVersion={appversion.version}
      isEnabled={true}
      isVerboseMode={false}
      metaFileDirectory={process.env.PUBLIC_URL}
    >
      <BrowserRouter future={{v7_startTransition:true, v7_relativeSplatPath:true}}>
        <Suspense fallback={<div className="text-center"><Spinner /></div>}>
          <Routes>
            <Route exact path="/privacy-policy" element={
              <PrivacyPolicy data={disclaimerData}/>
            } />
            <Route exact path="/cyber-security" element={
              <CyberSecurity data={disclaimerData}/>
            } />
            <Route exact path="/accessibility-statement" element={
              <AccessibilityStatement data={disclaimerData} />
            } />
            <Route exact path="/nmls-information" element={
              <NMLSInformation data={disclaimerData} />
            } />
            <Route exact path="/terms" element={
              <Terms data={disclaimerData} />
            } />
            <Route exact path="/sitemap" element={
              <Sitemap data={disclaimerData}/>
            } />
            <Route exact path="/customers" element={
              <Customer data={disclaimerData}/>
            } />
            <Route exact path="/careers" element={
              <CareerPage data={disclaimerData} />
            } />
            <Route exact path="/contactus" element={
              <ContactUs data={disclaimerData} />
            } />
            <Route exact path="/txdis" element={
              <Texasdisclosure data={disclaimerData} />
            } />
            <Route exact path="/*" element={
              <Notfoundpage />
            } />
            <Route exact path="/" element={
              <Home />
            } />
          </Routes>
        </Suspense>
        
      </BrowserRouter>
    </CacheBuster>
  );
}

export default App;
